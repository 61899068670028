import React from 'react';

export const IconCandyShopOutlined: React.FC = () => {
  return (
    <svg width="115" height="16" viewBox="0 0 115 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.58547 3.2588H9.24178C9.89277 3.2588 10.4205 3.78653 10.4205 4.43752V11.0938C10.4205 11.7448 9.89277 12.2725 9.24178 12.2725H2.58547C1.93448 12.2725 1.40675 11.7448 1.40675 11.0938V4.43752C1.40675 3.78653 1.93448 3.2588 2.58547 3.2588Z"
        stroke="var(--candy-shop-activate-icon-color)"
        strokeWidth="2.0801"
      />
      <mask id="path-2-inside-1_7544_61" fill="white">
        <path d="M2.58545 0.832038C2.58545 0.372516 2.95797 0 3.41749 0H8.40972C8.86924 0 9.24176 0.372516 9.24176 0.832038C9.24176 1.29156 8.86924 1.66408 8.40972 1.66408H3.41749C2.95797 1.66408 2.58545 1.29156 2.58545 0.832038Z" />
      </mask>
      <path
        d="M3.41749 1.73341H8.40972V-1.73341H3.41749V1.73341ZM8.40972 -0.0693367H3.41749V3.39749H8.40972V-0.0693367ZM3.41749 -0.0693367C3.9153 -0.0693367 4.31886 0.334222 4.31886 0.832038H0.852036C0.852036 2.2489 2.00063 3.39749 3.41749 3.39749V-0.0693367ZM7.50834 0.832038C7.50834 0.334222 7.9119 -0.0693367 8.40972 -0.0693367V3.39749C9.82658 3.39749 10.9752 2.2489 10.9752 0.832038H7.50834ZM8.40972 1.73341C7.9119 1.73341 7.50834 1.32985 7.50834 0.832038H10.9752C10.9752 -0.584822 9.82658 -1.73341 8.40972 -1.73341V1.73341ZM3.41749 -1.73341C2.00063 -1.73341 0.852036 -0.584822 0.852036 0.832038H4.31886C4.31886 1.32985 3.9153 1.73341 3.41749 1.73341V-1.73341Z"
        fill="var(--candy-shop-activate-icon-color)"
        mask="url(#path-2-inside-1_7544_61)"
      />
      <path
        d="M5.56692 5.82423C5.56692 6.39863 5.10127 6.86428 4.52687 6.86428C3.95246 6.86428 3.48682 6.39863 3.48682 5.82423C3.48682 5.24983 3.95246 4.78418 4.52687 4.78418C5.10127 4.78418 5.56692 5.24983 5.56692 5.82423Z"
        fill="var(--candy-shop-activate-icon-color)"
        stroke="var(--candy-shop-activate-icon-color)"
        strokeWidth="0.693365"
      />
      <path
        d="M8.89504 8.04274C8.89504 8.61714 8.4294 9.08278 7.85499 9.08278C7.28059 9.08278 6.81494 8.61714 6.81494 8.04274C6.81494 7.46833 7.28059 7.00269 7.85499 7.00269C8.4294 7.00269 8.89504 7.46833 8.89504 8.04274Z"
        fill="var(--candy-shop-activate-icon-color)"
        stroke="var(--candy-shop-activate-icon-color)"
        strokeWidth="0.693365"
      />
      <path
        d="M5.01223 9.70704C5.01223 10.2814 4.54658 10.7471 3.97218 10.7471C3.39778 10.7471 2.93213 10.2814 2.93213 9.70704C2.93213 9.13264 3.39778 8.667 3.97218 8.667C4.54658 8.667 5.01223 9.13264 5.01223 9.70704Z"
        fill="var(--candy-shop-activate-icon-color)"
        stroke="var(--candy-shop-activate-icon-color)"
        strokeWidth="0.693365"
      />
      <path
        d="M27.3018 9.23503C27.086 9.96208 26.7452 10.5698 26.2794 11.0583C25.8137 11.5468 25.2627 11.9103 24.6265 12.1489C23.9904 12.3875 23.2917 12.5067 22.5306 12.5067C21.4173 12.5067 20.4517 12.2739 19.6338 11.8081C18.8272 11.3423 18.2024 10.6778 17.7593 9.8144C17.3277 8.95103 17.1118 7.91726 17.1118 6.71309C17.1118 5.50892 17.3277 4.47515 17.7593 3.61178C18.2024 2.74842 18.8272 2.08385 19.6338 1.61809C20.4517 1.15232 21.4059 0.91944 22.4965 0.91944C23.269 0.91944 23.9676 1.02736 24.5924 1.2432C25.2172 1.44768 25.7455 1.76577 26.1772 2.19745C26.6202 2.62913 26.944 3.17442 27.1485 3.83331L25.0184 4.73643C24.8253 4.05483 24.53 3.57202 24.1324 3.28802C23.7348 2.99266 23.2235 2.84498 22.5987 2.84498C21.9853 2.84498 21.4457 2.99266 20.9799 3.28802C20.5255 3.58338 20.1734 4.02075 19.9234 4.60011C19.6735 5.16812 19.5486 5.87244 19.5486 6.71309C19.5486 7.54238 19.6622 8.2467 19.8894 8.82607C20.1279 9.40543 20.4744 9.8428 20.9288 10.1382C21.3832 10.4335 21.9399 10.5812 22.5987 10.5812C23.2122 10.5812 23.7404 10.4165 24.1835 10.087C24.6379 9.7576 24.956 9.25775 25.1377 8.58751L27.3018 9.23503Z"
        fill="var(--candy-shop-activate-icon-color)"
      />
      <path
        d="M33.9239 6.90053C33.9239 6.41205 33.7819 6.03148 33.4979 5.75884C33.2253 5.4862 32.8617 5.34988 32.4073 5.34988C31.987 5.34988 31.6178 5.4578 31.2997 5.67364C30.9817 5.88948 30.7488 6.24733 30.6011 6.74717L28.6074 6.13373C28.8005 5.38396 29.2208 4.78187 29.8684 4.32747C30.5272 3.87307 31.4077 3.64586 32.5096 3.64586C33.2594 3.64586 33.9126 3.76514 34.4692 4.00371C35.0258 4.23091 35.4575 4.57739 35.7643 5.04316C36.0823 5.50892 36.2414 6.09396 36.2414 6.79829V10.2574C36.2414 10.6891 36.4459 10.905 36.8548 10.905C37.0479 10.905 37.2297 10.8766 37.4001 10.8198L37.2638 12.2682C36.9684 12.4159 36.5992 12.4897 36.1562 12.4897C35.7586 12.4897 35.4007 12.4215 35.0826 12.2852C34.7646 12.1489 34.5146 11.9444 34.3329 11.6718C34.1511 11.3878 34.0602 11.0356 34.0602 10.6153V10.5471L34.4692 10.479C34.401 10.9106 34.2136 11.2799 33.9069 11.5866C33.6002 11.8819 33.2253 12.1091 32.7822 12.2682C32.3505 12.4272 31.9018 12.5067 31.4361 12.5067C30.8567 12.5067 30.3568 12.4215 29.9365 12.2511C29.5162 12.0694 29.1924 11.8081 28.9652 11.4673C28.738 11.1265 28.6244 10.7062 28.6244 10.2063C28.6244 9.57015 28.8289 9.05327 29.2379 8.65567C29.6468 8.2467 30.2319 7.96838 30.993 7.8207L34.1966 7.17317L34.1795 8.62159L32.1347 9.03055C31.7485 9.11007 31.4531 9.21799 31.2486 9.35431C31.0555 9.47927 30.9589 9.68944 30.9589 9.9848C30.9589 10.2688 31.0612 10.4846 31.2657 10.6323C31.4815 10.78 31.7371 10.8538 32.0325 10.8538C32.271 10.8538 32.5039 10.8254 32.7311 10.7686C32.9583 10.7118 33.1628 10.621 33.3446 10.496C33.5263 10.371 33.6683 10.2006 33.7706 9.9848C33.8728 9.76896 33.9239 9.50767 33.9239 9.20095V6.90053Z"
        fill="var(--candy-shop-activate-icon-color)"
      />
      <path
        d="M38.8522 12.3363V3.81627H40.9482L41.0845 6.37229L40.7948 6.09964C40.9311 5.543 41.147 5.08292 41.4424 4.71939C41.7377 4.35587 42.0956 4.08891 42.5159 3.91851C42.9476 3.73674 43.419 3.64586 43.9302 3.64586C44.5209 3.64586 45.0435 3.75946 45.4979 3.98667C45.9637 4.21387 46.3272 4.56035 46.5885 5.02612C46.8611 5.48052 46.9974 6.0542 46.9974 6.74717V12.3363H44.6629V7.41174C44.6629 6.68469 44.5209 6.18485 44.2369 5.9122C43.9529 5.6282 43.5837 5.4862 43.1293 5.4862C42.8112 5.4862 42.4988 5.56572 42.1921 5.72476C41.8968 5.87244 41.6525 6.11668 41.4594 6.45749C41.2776 6.79829 41.1867 7.2527 41.1867 7.8207V12.3363H38.8522Z"
        fill="var(--candy-shop-activate-icon-color)"
      />
      <path
        d="M52.6231 12.5067C51.9188 12.5067 51.2883 12.3307 50.7316 11.9785C50.1863 11.6263 49.7547 11.1208 49.4366 10.4619C49.1299 9.80304 48.9765 9.01351 48.9765 8.09334C48.9765 7.15045 49.1412 6.34957 49.4707 5.69068C49.8001 5.02044 50.2431 4.51491 50.7998 4.17411C51.3678 3.82194 52.004 3.64586 52.7083 3.64586C53.458 3.64586 54.0772 3.86171 54.5657 4.29339C55.0541 4.72507 55.3722 5.28172 55.5199 5.96332L55.2984 6.23597V0.0844727H57.6329V12.3363H55.5369L55.3836 9.74624L55.6733 9.88256C55.5938 10.4165 55.412 10.8822 55.128 11.2799C54.844 11.6661 54.4861 11.9671 54.0545 12.183C53.6228 12.3988 53.1456 12.5067 52.6231 12.5067ZM53.3047 10.7175C53.7137 10.7175 54.0658 10.6153 54.3612 10.4108C54.6565 10.195 54.8837 9.88256 55.0428 9.47359C55.2132 9.06463 55.2984 8.57615 55.2984 8.00814C55.2984 7.46286 55.2189 7.00277 55.0598 6.62789C54.9008 6.24165 54.6736 5.94628 54.3782 5.7418C54.0829 5.53732 53.7364 5.43508 53.3388 5.43508C52.7026 5.43508 52.2028 5.66228 51.8392 6.11668C51.4871 6.55973 51.311 7.21293 51.311 8.0763C51.311 8.93967 51.4871 9.59856 51.8392 10.053C52.1914 10.496 52.6799 10.7175 53.3047 10.7175Z"
        fill="var(--candy-shop-activate-icon-color)"
      />
      <path
        d="M68.0947 3.81627L64.6526 12.8646C64.38 13.6371 64.0733 14.2505 63.7325 14.7049C63.403 15.1593 63.0168 15.4888 62.5737 15.6932C62.142 15.8977 61.6252 16 61.0231 16C60.58 16 60.1711 15.9432 59.7962 15.8296C59.4327 15.7273 59.1146 15.591 58.8419 15.4206L59.3361 13.7336C59.5519 13.904 59.7791 14.0233 60.0177 14.0915C60.2563 14.171 60.5289 14.2108 60.8356 14.2108C61.1537 14.2108 61.4264 14.1426 61.6536 14.0063C61.8921 13.87 62.0909 13.5973 62.25 13.1883L62.5396 12.4556L61.6024 10.2404L58.9953 3.81627H61.5854L63.2042 8.92831L63.6473 10.6323L64.1755 8.82607L65.7262 3.81627H68.0947Z"
        fill="var(--candy-shop-activate-icon-color)"
      />
      <path
        d="M78.5425 0.91944C79.5649 0.91944 80.4623 1.09552 81.2348 1.44768C82.0073 1.79985 82.6662 2.32241 83.2115 3.01538L81.8994 4.53195C81.4336 3.94123 80.9224 3.50954 80.3658 3.2369C79.8205 2.9529 79.1843 2.8109 78.4573 2.8109C77.9802 2.8109 77.5826 2.87338 77.2645 2.99834C76.9577 3.1233 76.7305 3.28802 76.5829 3.4925C76.4465 3.69698 76.3784 3.92419 76.3784 4.17411C76.3784 4.48083 76.4977 4.74211 76.7362 4.95795C76.9748 5.16244 77.3838 5.33284 77.9631 5.46916L80.059 5.94628C81.1723 6.19621 81.9675 6.57109 82.4447 7.07093C82.9332 7.55942 83.1774 8.19558 83.1774 8.97943C83.1774 9.71784 82.9843 10.354 82.598 10.8879C82.2118 11.4105 81.6722 11.8138 80.9792 12.0978C80.2863 12.3704 79.491 12.5067 78.5936 12.5067C77.8098 12.5067 77.0884 12.4159 76.4295 12.2341C75.782 12.041 75.2026 11.7797 74.6914 11.4503C74.1802 11.1208 73.7542 10.7402 73.4134 10.3086L74.7425 8.72383C75.0038 9.08735 75.3276 9.41111 75.7138 9.69512C76.1001 9.97912 76.5374 10.2063 77.0259 10.3767C77.5257 10.5358 78.0483 10.6153 78.5936 10.6153C79.0594 10.6153 79.4513 10.5642 79.7694 10.4619C80.0988 10.3483 80.3431 10.195 80.5021 10.0018C80.6725 9.79736 80.7577 9.55311 80.7577 9.26911C80.7577 8.97375 80.6555 8.72383 80.451 8.51935C80.2579 8.3035 79.8943 8.13878 79.3604 8.02518L77.077 7.51398C76.4181 7.35494 75.8501 7.15045 75.373 6.90053C74.9072 6.63925 74.5494 6.30981 74.2995 5.9122C74.0609 5.50324 73.9416 5.02044 73.9416 4.46379C73.9416 3.79355 74.1234 3.19146 74.4869 2.65754C74.8504 2.12361 75.373 1.70329 76.0546 1.39656C76.7476 1.07848 77.5769 0.91944 78.5425 0.91944Z"
        fill="var(--candy-shop-activate-icon-color)"
      />
      <path
        d="M85.1635 12.3363V0.0844727H87.498V5.26468C87.7707 4.71939 88.1455 4.31611 88.6227 4.05483C89.0998 3.78218 89.6394 3.64586 90.2415 3.64586C90.8322 3.64586 91.3548 3.75946 91.8092 3.98667C92.2749 4.21387 92.6385 4.56035 92.8997 5.02612C93.1724 5.48052 93.3087 6.0542 93.3087 6.74717V12.3363H90.9742V7.42878C90.9742 6.69037 90.8322 6.18485 90.5482 5.9122C90.2642 5.6282 89.9007 5.4862 89.4576 5.4862C89.1282 5.4862 88.8101 5.56572 88.5034 5.72476C88.208 5.87244 87.9638 6.12236 87.7706 6.47453C87.5889 6.81533 87.498 7.26974 87.498 7.83774V12.3363H85.1635Z"
        fill="var(--candy-shop-activate-icon-color)"
      />
      <path
        d="M99.5989 3.64586C100.462 3.64586 101.218 3.82194 101.865 4.17411C102.524 4.52627 103.035 5.0318 103.399 5.69068C103.774 6.34957 103.961 7.14477 103.961 8.0763C103.961 8.99647 103.774 9.79168 103.399 10.4619C103.035 11.1208 102.524 11.6263 101.865 11.9785C101.218 12.3307 100.462 12.5067 99.5989 12.5067C98.7469 12.5067 97.9915 12.3307 97.3326 11.9785C96.6737 11.6263 96.1568 11.1208 95.7819 10.4619C95.4184 9.79168 95.2366 8.99647 95.2366 8.0763C95.2366 7.14477 95.4184 6.34957 95.7819 5.69068C96.1568 5.0318 96.6737 4.52627 97.3326 4.17411C97.9915 3.82194 98.7469 3.64586 99.5989 3.64586ZM99.5989 5.38396C99.1672 5.38396 98.798 5.4862 98.4913 5.69068C98.1959 5.8838 97.9687 6.17917 97.8097 6.57677C97.6507 6.97437 97.5711 7.47422 97.5711 8.0763C97.5711 8.67839 97.6507 9.17823 97.8097 9.57584C97.9687 9.97344 98.1959 10.2745 98.4913 10.479C98.798 10.6721 99.1672 10.7686 99.5989 10.7686C100.019 10.7686 100.377 10.6721 100.672 10.479C100.979 10.2745 101.212 9.97344 101.371 9.57584C101.53 9.17823 101.61 8.67839 101.61 8.0763C101.61 7.47422 101.53 6.97437 101.371 6.57677C101.212 6.17917 100.979 5.8838 100.672 5.69068C100.377 5.4862 100.019 5.38396 99.5989 5.38396Z"
        fill="var(--candy-shop-activate-icon-color)"
      />
      <path
        d="M105.915 15.9148V3.81627H108.01L108.164 6.52565L107.874 6.21893C107.976 5.71908 108.164 5.27604 108.436 4.88979C108.72 4.50355 109.078 4.20251 109.51 3.98667C109.942 3.75946 110.424 3.64586 110.958 3.64586C111.651 3.64586 112.271 3.82194 112.816 4.17411C113.361 4.52627 113.787 5.0318 114.094 5.69068C114.412 6.33821 114.571 7.12773 114.571 8.05926C114.571 8.99079 114.412 9.79168 114.094 10.4619C113.776 11.1208 113.338 11.6263 112.782 11.9785C112.236 12.3307 111.612 12.5067 110.907 12.5067C110.158 12.5067 109.533 12.2966 109.033 11.8763C108.544 11.4559 108.209 10.9277 108.028 10.2915L108.249 9.93368V15.9148H105.915ZM110.226 10.7175C110.851 10.7175 111.339 10.496 111.691 10.053C112.055 9.59856 112.236 8.93967 112.236 8.0763C112.236 7.21293 112.06 6.55973 111.708 6.11668C111.367 5.66228 110.885 5.43508 110.26 5.43508C109.862 5.43508 109.51 5.543 109.203 5.75884C108.908 5.96332 108.675 6.26437 108.505 6.66197C108.334 7.04821 108.249 7.51966 108.249 8.0763C108.249 8.62159 108.329 9.09303 108.488 9.49063C108.647 9.88824 108.874 10.195 109.169 10.4108C109.476 10.6153 109.828 10.7175 110.226 10.7175Z"
        fill="var(--candy-shop-activate-icon-color)"
      />
    </svg>
  );
};
